import { default as $ } from 'jquery';

$(document).ready(function () {
    $(".m-vacancies-job-button").on("click", function(){
        if ($(this).closest(".m-vacancies-job").hasClass("-noShow")) {
            $(".m-vacancies-job-content").slideUp();
            $(".m-vacancies-job").addClass("-noShow");
            $(".m-vacancies-chevron").addClass("fa-chevron-down").removeClass("fa-chevron-up");
            $(this).closest(".m-vacancies-job").removeClass("-noShow");
            $(this).closest(".m-vacancies-job").find(".m-vacancies-job-content").slideToggle();
            $(this).closest(".m-vacancies-job").find(".m-vacancies-chevron").toggleClass("fa-chevron-down").toggleClass("fa-chevron-up");
         } else {
            $(this).closest(".m-vacancies-job").addClass("-noShow");
            $(".m-vacancies-job-content").slideUp();
            $(".m-vacancies-chevron").addClass("fa-chevron-down").removeClass("fa-chevron-up");
         };
    });

    $("#jobButton").on("click", function(){
        var role = $('#role').find(":selected").val();
        var type = $('#type').find(":selected").val();
        var location = $('#location').find(":selected").val();

        if(role !== '' || type !== '' || location !== '') {
            $(".m-vacancies-job").addClass("-hide");

            var filtered = $(".m-vacancies-job");

            // Filter based on all of them 
            filtered = filtered.filter(function() {
                console.log($(this).data("role"));
                console.log(role);
                return RegExp(role).test($(this).data("role")) &&
                    RegExp(type).test($(this).data("type")) &&
                    RegExp(location).test($(this).data("location"));
            });

            if(filtered.length === 0) {
                $("#noResults").removeClass("-hide");
            } else {
                $("#noResults").addClass("-hide");
            };

            // Display Them
            filtered.each(function (i) {
                $(this).removeClass("-hide");
                console.log(filtered);
            });
        };
    });
});

function filterJobs2() {
    $('.m-vacancies-job').each(function(){
        var jobRole = $(this).data("role");
        var jobType = $(this).data("type");
        var jobLocation = $(this).data("location");

        if(jobRole === role) { 
            $(this).removeClass("-hide");
        };
    });
};

function filterJobs() {
    $(".m-vacancies-job").addClass("-hide");

	var filtered = $(".m-vacancies-job");

	// Filter based on all of them 
	filtered = filtered.filter(function() {
        console.log($(this).data("role"));
        console.log(role);
		return RegExp(role).test($(this).data("role")) &&
			RegExp(type).test($(this).data("type")) &&
			RegExp(location).test($(this).data("location"));
	});

	// Display Them
	filtered.each(function (i) {
		$(this).removeClass("-hide");
        console.log(filtered);
	});
};