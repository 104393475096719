import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$( document ).ready(function() {
    $('.m-marquee-row.-isCarousel').slick({
        dots: false,
        arrows: false,
        draggable: false,
        margin: 20,
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        cssEase: 'linear',
        controls: true,
        autoplay: true,
        autoplaySpeed: 0,
        pauseOnHover: true,
        variableWidth: true,
        useAutoplayToggleButton: false,
        speed: 12000,
        rows: 0,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    speed: 10000,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    speed: 8000,
                }
            },
        ]
    });
});