import 'jquery';
import { default as $ } from 'jquery';

$(document).ready(function () {
    $('#mobileButton').on('click', function(){
        $('#mobileButtonIcon').toggleClass('fa-x').toggleClass('fa-bars');
        $('#mobileDropdown').slideToggle();
        $('#header').toggleClass('-bg');
        $('#body').toggleClass('-no-scroll');

        if ($(window).width() > 768) {
            $('#header').toggleClass('-dark');  
            
        };
    });

    if ($(window).width() < 992) {
        $('#header').addClass('-dark');  
        
    } else {
        $(window).on('scroll', function () {
            var scroll = $(window).scrollTop();
    
            if (scroll >= 100) {
                $('#header').addClass("-dark");
            } else {
                $('#header').removeClass("-dark");
            }
        });  
    };

    window.onresize = function() {
        if ($(window).width() > 992) {
            $('#mobileDropdown').slideUp();
        };

        if ($(window).width() < 768) {
            $('#header').addClass('-dark');  
            
        } else {
            $('#header').removeClass('-dark');
    
            $(window).scroll(function() {
                var scroll = $(window).scrollTop();
        
                if (scroll >= 100) {
                    $('#header').addClass("-dark");
                } else {
                    $('#header').removeClass("-dark");
                }
            });    
        };

    }
});

if ($(window).width() < 992) {
    $('.menu-item-has-children').on('click', function(){
        $(this).toggleClass('-show');
    });
};