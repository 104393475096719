import { default as $ } from 'jquery';

$(document).ready(function () {
    $("#mapFilterButton").on("click", function(){
        $('.m-locations-filter-section').slideToggle();
        $('#filterChevron').toggleClass('fa-chevron-down');
        $('#filterChevron').toggleClass('fa-chevron-up');
    });

    function filterCategory() {
        let areaSelected = $('.m-locations-fieldset input[type=checkbox]:checked').map(function () {
            return $(this).attr('id');
        }).get();
    
        // reset results list
        $('.m-locations-store').removeClass('-active');
    
        // filtering
        $('.m-locations-store').each(function(){
            let $card = $(this);
            let dataArea = $(this).data('cat');
    
            areaSelected.forEach(function(item, index){
                if(dataArea.indexOf(item) !== -1 ) {
                    $card.addClass('-active');
                };
            });
            
            if(areaSelected.length === 0){
                $('.m-locations-store').addClass('-active');
            }
        });
    };

    function resetFilter() {
        $('.m-locations-store').addClass('-active');
        $('.m-locations-form-area-input').prop('checked', false);
    };

    $('.m-locations-store').addClass('-active');

    $('#mapFilter :checkbox').change(function (){
        filterCategory();
		$(".location-click").removeClass("selected");
    });

    $('#store-locator-postcode-input').change(function (){
        resetFilter();
    });
});

if ($(window).width() < 992) {
    $('.m-locations-filter-my').empty();
} else {
    $('.m-locations-stores-form').empty();
};