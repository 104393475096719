import { default as $ } from 'jquery';

$(document).ready(function() {
    $(".m-vacancies-job-link").on("click", function(){
        var name = $(this).attr('data-job');
        $('#nf-field-26').val(name);

        $('html, body').animate({
            scrollTop: $("#job-contact").offset().top
        });
    });
});