// This file contains all parachute imports, e.g:
import './accordion/accordion';
import './animation/animation';
import './basket-overview/basket-overview';
import './basket-update/basket-update';
import './mmenu/mmenu';
import './header/header';
import './carousel/carousel';
import './marquee/marquee';
import './gallery/gallery';
import './carousel/menu';
import './location/location';
import './food/food';
import './food/menu';
import './filter/map-filter';
import './vacancy/vacancy';
import './careers/careers';
import './valentines/valentines';

// Publicly accessible methods for this build, useful for callbacks in script tags etc.
// Try and keep most things inside this webpack bundle though
import { publicExports } from './public-exports';
window._Parachute = publicExports || {};
