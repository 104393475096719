import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$('.m-carousel-posts.-isCarousel').slick({
    dots: false,
    infinite: false,
    margin: 20,
    slidesToShow: 3,
    arrows: false,
    draggable: true,
    pauseOnHover: false,
    slidesToScroll: 1,
    controls: true,
    autoplay: false,
    rows: 0,
    autoplaySpeed: 4000,
    responsive: [
        {
            breakpoint: 1400,
            settings: {
                slidesToShow: 2,
            }
        },
        {
            breakpoint: 767,

            settings: {
                slidesToShow: 1,
            }
        }
    ]
});
