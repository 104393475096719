import 'jquery';
import { default as $ } from 'jquery';
import '@accessible360/accessible-slick';

$(window).on("load",function() {
   $(".m-food-allergens-chevron").removeClass("-upsideDown");
});

$( document ).ready(function() {
    $(".m-food-allergens-heading").on("click", function(){
        $(this).closest(".m-food-allergens").find(".m-food-allergens-dropdown").slideToggle();
        $(this).closest(".m-food-allergens").find(".m-food-allergens-chevron").toggleClass("-upsideDown");
    });

    $(".m-food-nutrition-heading").on("click", function(){
        $(this).closest(".m-food-nutrition").find(".m-food-nutrition-dropdown").slideToggle();
        $(this).closest(".m-food-nutrition").find(".m-food-nutrition-chevron").toggleClass("-upsideDown");
    });
    
    $(".m-food-image").on("click", function(){
        $('.m-food-image').removeClass('-hide');
        $(this).addClass('-hide');
        var url = $("img", this).attr('src');
        $('#foodImg').attr('src', url);
    });

    if($('.m-popular-sides > div').length > 4) {
        $('.m-popular-sides').addClass('-isCarousel');
    };

    if ($(window).width() < 768) {
        $('.m-popular-sides').addClass('-isCarousel');
    };
    
    $('.m-popular-sides.-isCarousel').slick({
        dots: false,
        infinite: false,
        margin: 20,
        slidesToShow: 5,
        arrows: false,
        draggable: true,
        pauseOnHover: false,
        slidesToScroll: 1,
        controls: true,
        autoplay: false,
        rows: 0,
        autoplaySpeed: 4000,
        responsive: [
            {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 4,
                }
            },
            {
                breakpoint: 1000,
                settings: {
                    slidesToShow: 3,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 565,
    
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });
});

