import { default as $ } from 'jquery';
// import '@accessible360/accessible-slick';

var startSlide = '0';

$(window).on("load",function() {
    $(".m-allergy-chevron").removeClass("-upsideDown");

    var url = window.location.pathname;
    var parts = url.split('/'),
    lastPart = parts.pop() == '' ? parts[parts.length - 1] : parts.pop();

    $(".m-menu-cat-section").each(function(){
        if($(this).data("cat") === lastPart){
            $(".m-menu-cat-section").removeClass("-selected");
            $(this).addClass("-selected");
            $(".m-menu-results").addClass("-hide");
            $("#"+lastPart).removeClass("-hide");
            startSlide = $(this).data("cat-index");
            return false;
        } else {
            $('.m-menu-cat-section:first').addClass('-selected');
            $('.m-menu-results:first').removeClass('-hide');
        };
    });
    
    $('.m-menu-cat-row.-isCarousel').slick({
        dots: false,
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev me-5 me-lg-0"><i class="fa-solid fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa-solid fa-chevron-right"></i></button>',
        appendArrows: $('.m-menu-cat-arrows'),
        draggable: true,
        slidesToShow: 9,
        slidesToScroll: 3,
        initialSlide: startSlide,
        infinite: false,
        controls: true,
        autoplay: false,
        autoplaySpeed: 4000,
        pauseOnHover: true,
        variableWidth: true,
        useAutoplayToggleButton: false,
        rows: 1,
        margin: 0,
        responsive: [
            {
                breakpoint: 1870,
                settings: {
                    slidesToShow: 9,
                }
            },
            {
                breakpoint: 1700,
                settings: {
                    slidesToShow: 8,
                }
            },
            {
                breakpoint: 1500,
                settings: {
                    slidesToShow: 7,
                }
            },
            {
                breakpoint: 1290,
                settings: {
                    slidesToShow: 6,
                }
            },
            {
                breakpoint: 1100,
                settings: {
                    slidesToShow: 5,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 4,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    swipeToSlide: true,
                    slidesToScroll: 1,
                    slidesToShow: 2,
                    centerMode: true,
                    centerPadding: 0,
                }
            },
            {
                breakpoint: 576,
                settings: {
                    swipeToSlide: true,
                    slidesToScroll: 1,
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: 0,
                }
            },
        ]
    });
    
    $(".m-menu-cat-section").on("click", function(){
        $('.m-menu-cat-section').removeClass('-selected');
        $(this).addClass('-selected');
        var cat = $(this).attr("data-cat");
        $('.m-menu-results').addClass('-hide');
        $('#'+cat).removeClass('-hide');
    });
    
    $(".m-menu-cat-row").find(".slick-track").addClass("-firstSlide");
    $(".slick-slide:last").addClass("-endSlide");
    
    if ($(window).width() < 768) {
        $('.m-menu-cat-row').on('beforeChange', function () { 
            if($(".slick-track").hasClass("-firstSlide")) {
                $(".slick-track").removeClass("-firstSlide");
            };
        });
    
        $(".m-menu-cat-row").on("afterChange", function (){
            $('.m-menu-cat-section').removeClass('-selected');
            $('.slick-current').find('.m-menu-cat-section').addClass('-selected');
            var cat = $('.slick-current').find('.m-menu-cat-section').attr("data-cat");
            $('.m-menu-results').addClass('-hide');
            $('#'+cat).removeClass('-hide');
            
            if($(".slick-current").data("slick-index") === 0) {
                $(".slick-track").addClass("-firstSlide");
            } else {
                $(".slick-track").removeClass("-firstSlide");
            };
        });
    
        $('.m-menu-cat-row').on('afterChange', function(event, slick, currentSlide) {
            if (slick.$slides.length-1 == currentSlide) {
                $(".slick-track").addClass("-lastSlide");
            } else {
                $(".slick-track").removeClass("-lastSlide");
            };
        });
    };
    
    if ($(window).width() > 768) {
        $('.m-menu-cat-row').on('afterChange', function() {
            if($('.-endSlide').hasClass('slick-active')) {
                $(".slick-track").addClass("-lastSlide");
            } else {
                $(".slick-track").removeClass("-lastSlide");
            };
        });
    };
    
    // Allergy filter
    $(".m-allergy-heading").on("click", function(){
        $(this).addClass("-open");
        $(this).closest(".m-allergy-accordion").find(".m-allergy-section").slideToggle();
        $(this).closest(".m-allergy-accordion").find(".m-allergy-chevron").toggleClass("fa-chevron-down").toggleClass("fa-chevron-up");
    });
    
    $(".m-allergy-form-clear").on("click", function(){
        $(".m-menu-result").removeClass("-hideAllergen");
    });
    
    $('#allergyFilter :checkbox').change(function (){
        filterAllergy();
    });
});

$( document ).ready(function() {
});

function filterAllergy() {
    let areaSelected = $('#allergyFilter input[type=checkbox]:checked').map(function () {
        return $(this).attr('id');
    }).get();

    // reset results list
    $('.m-menu-result').removeClass('-hideAllergen');

    // filtering
    $('.m-menu-result').each(function(){
        let $card = $(this);
        let dataArea = $(this).attr('class').split(/\s+/);

        if(areaSelected.some( ai => dataArea.includes(ai) )) {
            $card.addClass('-hideAllergen');
        }
        
        if(areaSelected.length === 0){
            $('.m-menu-result').removeClass('-hideAllergen');
        }
    });
};